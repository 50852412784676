@import "variables";
// includes
@import "includes/functions";
@import "includes/normalize";
@import "includes/45p";
@import "includes/fonts";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.min"; 
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body { 
    background-color: $black;
    color: $white;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
    text-transform: uppercase;
}

@media screen and (max-width: 900px){
    video.desktop{
        display: none;
    }
}

@media screen and (min-width: 901px){
    video.mobile{
        display: none;
    }
}

a{
    color: inherit;
    @include hover{
        color: $primary-color;
    }
}

#header{
    position: relative;
    height: 100vh;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    
    video{
        position: absolute;
        min-width: 100vw;
        min-height: 100vh;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: auto;
        z-index: 1;
    }

    .container{
        text-align: center;
        position: relative;
        z-index: 10;
        max-width: rem(610);
        img{
            width: 100%;
        }
        @media screen and (max-width: 400px){
            max-width: rem(280);
        }
    }

    .btn{
        font-size: rem(16);
        margin-top: rem(20);
        font-family: "Neue Plak W05 Narrow SemiBold", sans-serif;
        border: 1px solid $white;
        display: inline-block;
        padding: rem(2) rem(5) rem(4);
        @include hover{
            color: $black;
            background-color: $white;
        }
        @media screen and (max-width: 400px){
            font-size: rem(13);
        }
    }
}

#main{
    .title{
        font-size: rem(25);
        font-family: "Neue Plak W05 Narrow Bold", sans-serif;
        margin: 0;
    }
    .ft{
        font-size: rem(20);
        font-family: "Neue Plak W05 Narrow Light", sans-serif;
        margin: rem(8) 0 rem(10);
    }

    .btn{
        font-size: rem(20);
        font-family: "Neue Plak W05 Narrow Light", sans-serif;
        border: 1px solid $white;
        display: inline-block;
        padding: rem(2) rem(10) rem(4);
        @include hover{
            color: $black;
            background-color: $white;
        }
    }
}

#music{
    h2{
        margin: 0 auto rem(30);
    }
    padding: rem(20) 0 rem(50);
    @media screen and (max-width: 600px){
        padding: rem(10) 0 rem(30);
    }
    text-align: center;
    .container{
        width: 100%;
        max-width: rem(960);
        margin: 0 auto;
    }
    .item{
        display: inline-block;
        img{
            display: block;
            margin: 0 auto rem(10);
        }
    }

    .owl-carousel{
        padding: 0 rem(30);
    }

    .owl-prev, .owl-next{
        display: block;
        position: absolute;
        top: 35%;
        transform: translateY(-50%);
        font-size: rem(30);
        text-align: center;
    }
    .owl-prev{
        left: 0;
    }
    .owl-next{
        right: 0;
    }
}

#video{
    display: flex;
    @media screen and (max-width: 600px){
        flex-direction: column;
        video.mobile{
            display: block;
        }
    }
    .item{
        position: relative;
    }

    .owl-prev, .owl-next{
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: rem(30);
        text-align: center;
    }
    .owl-prev{
        left: rem(10);
    }
    .owl-next{
        right: rem(10);
    }
    video{
        width: 100%;
        height: auto;
    }

    .content{
        text-align: center;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        padding: 0 rem(10);
        transform: translateY(-50%);
    }
    .btn{
        font-size: rem(16);
    }
}

#tour{
    width: 100%;
    max-width: rem(800);
    margin: rem(40) auto;
    .tour-cta{
        margin: rem(30) auto;
        display: block;
        width: rem(600);
        max-width:100%;
    }
    h2{
        text-align: center;
        letter-spacing: .1em;
        font-family: "Neue Plak W05 Narrow Bold", sans-serif;
        margin-bottom: rem(30);
    }
    .bit-top-track-button, .bit-logo-container{
        display: none;
    }
}

#footer{
    padding-top: rem(40);
    text-align: center;
    @media screen and (max-width: 600px){
        padding-top: rem(20);
    }

    h2{

        font-size: rem(25);
        font-family: "Neue Plak W05 Narrow Bold", sans-serif;
        font-weight: normal;
        margin-bottom: rem(20);
        
        @media screen and (max-width: 600px){
            font-size: rem(18);
            margin-bottom: rem(10);
        }
    }
    .social{
        margin: rem(10);
        display: inline-block;
        vertical-align: middle;
        li{
            display: inline-block;
            &+li{
                margin-left: .5em;
            }
        }
        @media screen and (max-width: 600px){
            font-size: rem(20);
        }
    }

    .newsletter{
        margin: rem(10);
        display: inline-block;
        vertical-align: middle;
        input, button{
            font-family: "Neue Plak W05 Narrow SemiBold", sans-serif;
            text-transform: uppercase;
            padding: rem(4) rem(8) rem(5);
            border: 1px solid $white;
            font-size: rem(11);
            color: $white;
            letter-spacing: .05em;

            @media screen and (max-width: 600px){
                font-size: rem(13);
            }
        }

        input{
            &::placeholder{
                color: $white;
            }
        }

        button{
            padding: rem(4) rem(18) rem(5);
            @include hover{
                color: $black;
                background-color: $white;
            }
        }
    }

    .logos{
        margin: rem(8) 0;
        img{
            height: rem(15);
            width: auto;
            margin: 0 rem(10);
            display: inline-block;
            vertical-align: middle;
        }
    }

    .copyright{
        font-size: rem(8); 
        color: #d3d3d3;
        text-transform: uppercase;
    }
}
#popup{
	width: 100%;
	max-width: rem(750);
	margin: rem(30) auto;
	position: relative;
}