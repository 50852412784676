//settings
$fa-font-path: "../font";

// Colors //
$black: #151515;
$white: #fff;
$primary-color: #e2223d;
$gray: #656565;

//Fonts
$primary-font: 'Helvetica', sans-serif;
